export const SITE_ID_TIPPLE = 1
export const SITE_ID_BUSINESS = 2
export const SITE_ID_WAREHOUSE = 3

export const PUBLIC_IMAGE_FOLDER = '/static/assets/img'
export const PUBLIC_ICON_FOLDER = '/static/assets/img/icons'

export const MAX_ORDER_COMMENT_LENGTH = 120

export const ORDER_TRACKING_INTERVAL_MS = 10000

export const PRODUCT_DEFAULT_QTY = 1

export const SITE_ID_SHOW_GIFT = ['0']

export default {
  SITE_ID_TIPPLE,
  SITE_ID_BUSINESS,
  SITE_ID_WAREHOUSE,
  PUBLIC_IMAGE_FOLDER,
  PUBLIC_ICON_FOLDER,
  MAX_ORDER_COMMENT_LENGTH,
  ORDER_TRACKING_INTERVAL_MS,
  PRODUCT_DEFAULT_QTY,
  SITE_ID_SHOW_GIFT,
}
