export default {
  domains: {
    'tipple.com.au': {
      siteId: 1,
      defaultStoreId: 731,
      segmentKey: '3EABbskPa4teN0756vp93BChdQZIh4zt',
      googleApiKey: 'AIzaSyACEHFddrTT-eFXRuM-8SekQ6ywuiKhv1E'
    },
    '7eleven.tipple.com.au': {
      siteId: 7,
      defaultStoreId: 176,
      unavailableComponent: '7ElevenUnavailable',
      segmentKey: 'jOHooI95EXfbvviKMKisgeCZrBaygdz2',
      googleApiKey: 'AIzaSyBwThblYNqbm1Vsi8gCeg7t_2f6VmART5Y',
      showDefaultPrice: true
    },
    'marketplace.tipple.com.au': {
      siteId: 3,
      defaultStoreId: 146,
      segmentKey: 'pkkYGTd93jbOP9y7vDrerSfQM4qDFWdF',
      googleApiKey: 'AIzaSyDLBSOImg2Y2tmiiqGv8mugxyNgbxhmPxI'
    },
    'railway.tipple.com.au': {
      siteId: 9,
      defaultStoreId: 152,
      segmentKey: 'K3OnOy8pmmYPpnbcf8w9OvsVTwUQFSEl',
      googleApiKey: 'AIzaSyD8euVT8Y9aDxpkiZs6ZS1qyo_pJa3AQs4'
    },
    'youfoodz.tipple.com.au': {
      siteId: 10,
      defaultStoreId: 194,
      unavailableComponent: '7ElevenUnavailable',
      segmentKey: 'h7uxHhyxfTvcVoRJIsKIGJHXUYHBP4u3'
    },
    'wotapopup.com': {
      siteId: 14,
      defaultStoreId: 681
    },
    'whitelabel.tipple.com.au': {
      siteId: 22,
      defaultStoreId: 727,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'cocktailhq.tipple.com.au': {
      siteId: 23,
      defaultStoreId: 751,
      segmentKey: 'Fc2T6ePBXtEdcE3uuXR31FR1WkLhMxfB'
    },
    'tpliquor.tipple.com.au': {
      siteId: 24,
      defaultStoreId: 728,
      segmentKey: 'd0n9cqGYca8eDrvwrPb86ly0DEepGX5L',
      googleApiKey: 'AIzaSyANkqkK8AyFYjbKf8D2VTu9QIdsK3FvOSY'
    },
    'thirstycamelhampton.tipple.com.au': {
      siteId: 25,
      defaultStoreId: 732,
      segmentKey: 'CRHVcYi6oFW24o4RQY2qcv3nr1m0QrTi',
      googleApiKey: 'AIzaSyC_a-Nj8e5OqAmlsVGDiPeAKIaqja1fAvE'
    },
    'igaeastbrighton.tipple.com.au': {
      siteId: 26,
      defaultStoreId: 733,
      segmentKey: 'sAOucDnhKnFXj0q1JLKqVi1Mrl08Hvz7',
      googleApiKey: 'AIzaSyCD94Mg0Dq0Bqzr4HxpD26TxAcIuD6doiY'
    },
    'cellarbrations.tipple.com.au': {
      siteId: 27,
      defaultStoreId: 728,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'bottleo.tipple.com.au': {
      siteId: 28,
      defaultStoreId: 728,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'liquorbarons.tipple.com.au': {
      siteId: 29,
      defaultStoreId: 728,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'bottler.tipple.com.au': {
      siteId: 30,
      defaultStoreId: 728,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'fleetstreet.tipple.com.au': {
      siteId: 31,
      defaultStoreId: 728,
      segmentKey: 'kTwdp6gV9hJi0eFcg6dDnERQ0VxCsrUM'
    },
    'thebottleostkilda.tipple.com.au': {
      siteId: 32,
      defaultStoreId: 734,
      segmentKey: 'GvEjxcG7b7QVvAjgow2cHEA7slVSpSHY',
      googleApiKey: 'AIzaSyCmFAZjXhdCYpRuU0sGVwwSOSxlWTdOv6c'
    },
    'australianwineclearance.tipple.com.au': {
      siteId: 33,
      defaultStoreId: 735,
      segmentKey: 'y04POxH7m9YBqLRHxbYSy6EAPC7MFc3k',
      googleApiKey: 'AIzaSyDx4mXfNzmockYT_iEE9CtlSnEHhHZwzAU'
    },
    'sessionsarden.tipple.com.au': {
      siteId: 34,
      defaultStoreId: 736,
      segmentKey: 'ABLggMU7uGEreG3CuwXy0ytQdA8aWccB',
      googleApiKey: 'AIzaSyCpucojc-oz0zbqxx2RC6_9ICv3Buyxp78'
    },
    'sessionskew.tipple.com.au': {
      siteId: 35,
      defaultStoreId: 737,
      segmentKey: 'h6Z6C3HQvxBA0soXxtocH42cPdt50HKA',
      googleApiKey: 'AIzaSyAgD9DDdwYYYW3fSu_wi6SqepgZZUopTeY'
    },
    'liquidhq.tipple.com.au': {
      siteId: 36,
      defaultStoreId: 750,
      segmentKey: 'RewNAnxaHFsj28uGshX1zvuHGIKWQeYS',
      googleApiKey: 'AIzaSyDgv1uWDgmEcDh6NF_cm1NeFykVloKgh5s'
    },
    'duncansmitcham.tipple.com.au': {
      siteId: 37,
      defaultStoreId: 738,
      segmentKey: 'kWUUkl7YBLI0pAVm2eo6F8Y2fhrQX68Q',
      googleApiKey: 'AIzaSyAJCYZK41oGeFAhDVkTxvi507v4k3sD0hs'
    },
    'bottleoofficer.tipple.com.au': {
      siteId: 38,
      defaultStoreId: 739,
      segmentKey: 'MnyH1FhcPOUhGbTm28M71iEYzR6jOFhX',
      googleApiKey: 'AIzaSyDNeBWjhLjFHaZ35froIQobvYGkTIzbdvo'
    },
    'cellarbrationsatvictoriamarket.tipple.com.au': {
      siteId: 39,
      defaultStoreId: 740,
      segmentKey: '7xs3uAtwZwp8JJCjTEr3XL7JOpQCG08g',
      googleApiKey: 'AIzaSyAhLzhEjmYgR4FV4vzWWCr7v2IaOxDHpEU'
    },
    'bottlemartportmelbourne.tipple.com.au': {
      siteId: 40,
      defaultStoreId: 741,
      segmentKey: 'yIO7BVEEAtBJTFzWVlIWJG9ZpLHLlixp',
      googleApiKey: 'AIzaSyAMZcDKgGVNzCOR0RXjHgi9l0ZBtYaonCc'
    },
    'cellarbrationsatnajda.tipple.com.au': {
      siteId: 41,
      defaultStoreId: 742,
      segmentKey: 'EYuzhMTHvk2TdZxSz0fLBlAW97bKvTmg',
      googleApiKey: 'AIzaSyC7e7efMeQlEs7-r0frzwkMzIFfEq5bva4'
    },
    'bottleolordnelson.tipple.com.au': {
      siteId: 42,
      defaultStoreId: 743,
      segmentKey: 'sNy6JTWFZUBhwD1KcsAg5QKPiyaIvqgD',
      googleApiKey: 'AIzaSyBhBn6SwbQ2j8uhKlBSmP2Z-ZoSHs_TQcM'
    },
    'igasunshinenorth.tipple.com.au': {
      siteId: 43,
      defaultStoreId: 744,
      segmentKey: 'PveH675hOHtr9MlIwqKWRBVbv6H0RvWy',
      googleApiKey: 'AIzaSyBblbaOpwYtBYEhmupcupYh63hkAEIH2Lk'
    }
  }
};
  
  