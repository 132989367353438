import React from 'react'
import { useSelector } from 'react-redux'
import Loadable from 'react-loadable'
import DefaultFooter from 'app/components/footer/templates/DefaultFooter'

const loadingError = (props) => {
  if (props.error) {
    throw new Error('CHUNK FAILED')
  }
  return null
}

const templates = {
  default: DefaultFooter,
  '7Eleven': Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "7ElevenFooter" */ 'app/components/footer/templates/7Eleven'
      ),
    loading: loadingError,
    modules: ['7ElevenFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/7Eleven'),
    ], // Prevents white flash
  }),
  Railway: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "RailwayFooter" */ 'app/components/footer/templates/Railway'
      ),
    loading: loadingError,
    modules: ['RailwayFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Railway'),
    ], // Prevents white flash
  }),
  YouFoodz: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "YouFoodzFooter" */ 'app/components/footer/templates/YouFoodz'
      ),
    loading: loadingError,
    modules: ['YouFoodzFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/YouFoodz'),
    ], // Prevents white flash
  }),
  Wotapopup: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "WotapopupFooter" */ 'app/components/footer/templates/Wotapopup'
      ),
    loading: loadingError,
    modules: ['WotapopupFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Wotapopup'),
    ], // Prevents white flash
  }),
  Bottlehouse: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottlehouseFooter" */ 'app/components/footer/templates/Bottlehouse'
      ),
    loading: loadingError,
    modules: ['BottlehouseFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Bottlehouse'),
    ], // Prevents white flash
  }),
  Chemist: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "ChemistFooter" */ 'app/components/footer/templates/Chemist'
      ),
    loading: loadingError,
    modules: ['ChemistFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Chemist'),
    ], // Prevents white flash
  }),
  TheCocktailCo: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "TheCocktailCoFooter" */ 'app/components/footer/templates/TheCocktailCo'
      ),
    loading: loadingError,
    modules: ['TheCocktailCoFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/TheCocktailCo'),
    ], // Prevents white flash
  }),
  TPLiquor: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "TPLiquorFooter" */ 'app/components/footer/templates/TPLiquor'
      ),
    loading: loadingError,
    modules: ['TPLiquorFooter'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/TPLiquor'),
    ], // Prevents white flash
  }),
  TCHampton: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "TCHamptonFooter" */ 'app/components/footer/templates/TCHampton'
      ),
    loading: loadingError,
    modules: ['TCHampton'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/TCHampton'),
    ], // Prevents white flash
  }),
  IGABrighton: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "IGABrightonFooter" */ 'app/components/footer/templates/IGABrighton'
      ),
    loading: loadingError,
    modules: ['IGABrighton'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/IGABrighton'),
    ], // Prevents white flash
  }),
  Cellarbrations: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "CellarbrationsFooter" */ 'app/components/footer/templates/Cellarbrations'
      ),
    loading: loadingError,
    modules: ['Cellarbrations'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Cellarbrations'),
    ], // Prevents white flash
  }),
  BottleO: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottleOFooter" */ 'app/components/footer/templates/BottleO'
      ),
    loading: loadingError,
    modules: ['BottleO'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/BottleO'),
    ], // Prevents white flash
  }),
  LiqBarons: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "LiqBaronsFooter" */ 'app/components/footer/templates/LiqBarons'
      ),
    loading: loadingError,
    modules: ['LiqBarons'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/LiqBarons'),
    ], // Prevents white flash
  }),
  Bottler: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottlerFooter" */ 'app/components/footer/templates/Bottler'
      ),
    loading: loadingError,
    modules: ['Bottler'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/Bottler'),
    ], // Prevents white flash
  }),
  FleetStreet: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "FleetStreetFooter" */ 'app/components/footer/templates/FleetStreet'
      ),
    loading: loadingError,
    modules: ['FleetStreet'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/FleetStreet'),
    ], // Prevents white flash
  }),
  BottleOStKilda: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottleOStKildaFooter" */ 'app/components/footer/templates/BottleOStKilda'
      ),
    loading: loadingError,
    modules: ['BottleOStKilda'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/BottleOStKilda'),
    ], // Prevents white flash
  }),
  AusWineClear: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "AusWineClearFooter" */ 'app/components/footer/templates/AusWineClear'
      ),
    loading: loadingError,
    modules: ['AusWineClear'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/AusWineClear'),
    ], // Prevents white flash
  }),
  LiquidHQ: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "LiquidHQFooter" */ 'app/components/footer/templates/LiquidHQ'
      ),
    loading: loadingError,
    modules: ['LiquidHQ'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/LiquidHQ'),
    ], // Prevents white flash
  }),
  SessionsArden: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "SessionsArdenFooter" */ 'app/components/footer/templates/SessionsArden'
      ),
    loading: loadingError,
    modules: ['SessionsArden'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/SessionsArden'),
    ], // Prevents white flash
  }),
  SessionsKew: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "SessionsKewFooter" */ 'app/components/footer/templates/SessionsKew'
      ),
    loading: loadingError,
    modules: ['SessionsKew'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/SessionsKew'),
    ], // Prevents white flash
  }),
  DuncansMitcham: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "DuncansMitchamFooter" */ 'app/components/footer/templates/DuncansMitcham'
      ),
    loading: loadingError,
    modules: ['DuncansMitcham'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/DuncansMitcham'),
    ], // Prevents white flash
  }),
  BottleOOfficer: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottleOOfficerFooter" */ 'app/components/footer/templates/BottleOOfficer'
      ),
    loading: loadingError,
    modules: ['BottleOOfficer'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/BottleOOfficer'),
    ], // Prevents white flash
  }),
  CBNVicMarket: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "CBNVicMarketFooter" */ 'app/components/footer/templates/CBNVicMarket'
      ),
    loading: loadingError,
    modules: ['CBNVicMarket'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/CBNVicMarket'),
    ], // Prevents white flash
  }),
  BtlPortMelb: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BtlPortMelbFooter" */ 'app/components/footer/templates/BtlPortMelb'
      ),
    loading: loadingError,
    modules: ['BtlPortMelb'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/BtlPortMelb'),
    ], // Prevents white flash
  }),
  CBNNajda: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "CBNNajdaFooter" */ 'app/components/footer/templates/CBNNajda'
      ),
    loading: loadingError,
    modules: ['CBNNajda'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/CBNNajda'),
    ], // Prevents white flash
  }),
  BottleONelson: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "BottleONelsonFooter" */ 'app/components/footer/templates/BottleONelson'
      ),
    loading: loadingError,
    modules: ['BottleONelson'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/BottleONelson'),
    ], // Prevents white flash
  }),
  IGASunshine: Loadable({
    loader: () =>
      import(
        /* webpackChunkName: "IGASunshineFooter" */ 'app/components/footer/templates/IGASunshine'
      ),
    loading: loadingError,
    modules: ['IGASunshine'],
    webpack: () => [
      require.resolveWeak('app/components/footer/templates/IGASunshine'),
    ], // Prevents white flash
  }),
}

const Footer = (props) => {
  const theme = useSelector((store) => store.theme)
  const componentName = theme?.footerComponent ?? 'default'
  const FooterComponent = templates[componentName] ?? templates['default']
  return <FooterComponent {...props} />
}

export default Footer
