export const CLEAR_GEOCODED_ADDRESS = 'CLEAR_GEOCODED_ADDRESS'
export const SET_INPUT_ADDRESS = 'SET_INPUT_ADDRESS'

export const CONFIRM_GEOCODED_ADDRESS_SUCCESS =
  'CONFIRM_GEOCODED_ADDRESS_SUCCESS'
export const CONFIRM_GEOCODED_ADDRESS_CANCEL = 'CONFIRM_GEOCODED_ADDRESS_CANCEL'
export const CONFIRM_GEOCODED_ADDRESS_REQUEST =
  'CONFIRM_GEOCODED_ADDRESS_REQUEST'

export const GEOCODE_ADDRESS_REQUEST = 'GEOCODE_ADDRESS_REQUEST'
export const GEOCODE_ADDRESS_SUCCESS = 'GEOCODE_ADDRESS_SUCCESS'
export const GEOCODE_ADDRESS_FAILURE = 'GEOCODE_ADDRESS_FAILURE'

export const STORE_UNAVAILABLE = 'STORE_UNAVAILABLE'

export const DISPLAY_ADDRESS_SELECT = 'DISPLAY_ADDRESS_SELECT'
export const HIDE_ADDRESS_SELECT = 'HIDE_ADDRESS_SELECT'
