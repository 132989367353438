export const ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'

export const ADD_BUNDLE_TO_CART_REQUEST = 'ADD_BUNDLE_TO_CART_REQUEST'
export const ADD_BUNDLE_TO_CART_SUCCESS = 'ADD_BUNDLE_TO_CART_SUCCESS'
export const ADD_BUNDLE_TO_CART_FAILURE = 'ADD_BUNDLE_TO_CART_FAILURE'

export const REMOVE_BUNDLE_FROM_CART_REQUEST = 'REMOVE_BUNDLE_FROM_CART_REQUEST'
export const REMOVE_BUNDLE_FROM_CART_SUCCESS = 'REMOVE_BUNDLE_FROM_CART_SUCCESS'
export const REMOVE_BUNDLE_FROM_CART_FAILURE = 'REMOVE_BUNDLE_FROM_CART_FAILURE'

export const SET_CART_ADDRESS_REQUEST = 'SET_CART_ADDRESS_REQUEST'
export const SET_CART_ADDRESS_SUCCESS = 'SET_CART_ADDRESS_SUCCESS'
export const SET_CART_ADDRESS_FAILURE = 'SET_CART_ADDRESS_FAILURE'

export const CHANGE_STORE = 'CHANGE_STORE'
export const CLOSE_CART = 'CLOSE_CART'

export const RETRIEVE_CART_REQUEST = 'RETRIEVE_CART_REQUEST'
export const RETRIEVE_CART_SUCCESS = 'RETRIEVE_CART_SUCCESS'
export const RETRIEVE_CART_FAILURE = 'RETRIEVE_CART_FAILURE'

export const SYNC_CART_REQUEST = 'SYNC_CART_REQUEST'
export const SYNC_CART_SUCCESS = 'SYNC_CART_SUCCESS'
export const SYNC_CART_FAILURE = 'SYNC_CART_FAILURE'

export const VERIFY_CART_REQUEST = 'VERIFY_CART_REQUEST'
export const VERIFY_CART_SUCCESS = 'VERIFY_CART_SUCCESS'
export const VERIFY_CART_FAILURE = 'VERIFY_CART_FAILURE'
// Used when we don't want to hit the API with a 404, so we consider the cart empty
// if they don't have an address or auth
export const VERIFY_CART_EMPTY = 'VERIFY_CART_EMPTY'

export const SET_DELIVERY_TIME_REQUEST = 'SET_DELIVERY_TIME_REQUEST'
export const SET_DELIVERY_TIME_SUCCESS = 'SET_DELIVERY_TIME_SUCCESS'
export const SET_DELIVERY_TIME_FAILURE = 'SET_DELIVERY_TIME_FAILURE'

export const APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST'
export const APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS'
export const APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE'
export const APPLY_PROMO_CODE_CLEAR = 'APPLY_PROMO_CODE_CLEAR'

export const REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST'
export const REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS'
export const REMOVE_PROMO_CODE_FAILURE = 'REMOVE_PROMO_CODE_FAILURE'

export const DISPLAY_SURCHARGE_POPUP = 'DISPLAY_SURCHARGE_POPUP'
export const HIDE_SURCHARGE_POPUP = 'HIDE_SURCHARGE_POPUP'

export const REFRESH_CART_REQUEST = 'REFRESH_CART_REQUEST'
export const REFRESH_CART_SUCCESS = 'REFRESH_CART_SUCCESS'
export const REFRESH_CART_FAILURE = 'REFRESH_CART_FAILURE'
