export const VERIFY_TOKEN_REQUEST = 'VERIFY_TOKEN_REQUEST'
export const VERIFY_TOKEN_SUCCESS = 'VERIFY_TOKEN_SUCCESS'
export const VERIFY_TOKEN_FAILURE = 'VERIFY_TOKEN_FAILURE'

export const VERIFY_TOKEN_SKIPSERVER = 'VERIFY_TOKEN_SKIPSERVER'

export const VERIFY_DEVICE_TOKEN_REQUEST = 'VERIFY_DEVICE_TOKEN_REQUEST'
export const VERIFY_DEVICE_TOKEN_SUCCESS = 'VERIFY_DEVICE_TOKEN_SUCCESS'
export const VERIFY_DEVICE_TOKEN_FAILURE = 'VERIFY_DEVICE_TOKEN_FAILURE'

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE'
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS'

export const SEND_FORGOT_PASSWORD_REQUEST = 'SEND_FORGOT_PASSWORD_REQUEST'
export const SEND_FORGOT_PASSWORD_SUCCESS = 'SEND_FORGOT_PASSWORD_SUCCESS'
export const SEND_FORGOT_PASSWORD_FAILURE = 'SEND_FORGOT_PASSWORD_FAILURE'

export const DISPLAY_LOGIN = 'DISPLAY_LOGIN'
export const HIDE_LOGIN = 'HIDE_LOGIN'

export const DISPLAY_FORGOT_PASSWORD = 'DISPLAY_FORGOT_PASSWORD'
export const HIDE_FORGOT_PASSWORD = 'HIDE_FORGOT_PASSWORD'

export const DISPLAY_REFERRAL_CODE = 'DISPLAY_REFERRAL_CODE'
export const HIDE_REFERRAL_CODE = 'HIDE_REFERRAL_CODE'

export const SEND_NOTIFY_EXPANDING_REQUEST = 'SEND_NOTIFY_EXPANDING_REQUEST'
export const SEND_NOTIFY_EXPANDING_SUCCESS = 'SEND_NOTIFY_EXPANDING_SUCCESS'
export const SEND_NOTIFY_EXPANDING_FAILURE = 'SEND_NOTIFY_EXPANDING_FAILURE'

export const DISPLAY_ADDRESS_UNSERVICEABLE = 'DISPLAY_ADDRESS_UNSERVICEABLE'
export const HIDE_ADDRESS_UNSERVICEABLE = 'HIDE_ADDRESS_UNSERVICEABLE'

export const DISPLAY_ADDRESS_ALTERNATIVE_STORE =
  'DISPLAY_ADDRESS_ALTERNATIVE_STORE'
export const HIDE_ADDRESS_ALTERNATIVE_STORE = 'HIDE_ADDRESS_ALTERNATIVE_STORE'

export const VERIFY_MOBILE_SUCCESS = 'VERIFY_MOBILE_SUCCESS'
